import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import * as fabric from 'fabric';
import Sidebar from './sidebar';

function TemplateCreate() {
  const [imageData, setImageData] = useState({});
  const [templateName, setTemplateName] = useState('');
  const [folderId, setFolderId] = useState('');
  const [backgroundImage, setBackgroundImage] =useState(null);
  const [club_logoImage, setClub_logoImage] =useState(null);
  const [position_fieldImage, setPosition_fieldImage] =useState(null);
  const [name_fieldImage, setName_fieldImage] =useState(null);
  const [sponsor_logoImage, setSponsor_logoImage] =useState(null);
  const [backsideImage, setBacksideImage] =useState(null);
  const [canvas, setCanvas] = useState(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  // Initialize Fabric.js canvas
useEffect(() => {
  if (canvasRef.current) {
    const initCanvas = new fabric.Canvas(canvasRef.current, {
      width: 400, // Default width
      height: 300, // Default height
    });

    initCanvas.backgroundColor = '#fff';
    initCanvas.renderAll();
    
    // Add event listener to track object movement and size
    initCanvas.on('object:modified', (event) => {
      const obj = event.target;
      if (obj && obj.id) {
        updateImageData(obj.id, obj);
        console.log(`Object position: x=${obj.left}, y=${obj.top}`);
        console.log(`Object size: width=${obj.width * obj.scaleX}, height=${obj.height * obj.scaleY}`);
      }
    });

    setCanvas(initCanvas);
    return () => {
      initCanvas.dispose();
    };
  }
}, []);

const setCanvasBackground = (file) => {
  if (!file || !canvas) return;

  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = (e) => {
    const bgImageUrl = e.target.result;
    const bgImageElement = document.createElement('img');
    bgImageElement.src = bgImageUrl;

    bgImageElement.onload = () => {
      const bgImage = new fabric.Image(bgImageElement);

      // Resize the canvas to match the image dimensions
      canvas.setWidth(bgImage.width);
      canvas.setHeight(bgImage.height);

      // Assign the background image directly
      canvas.backgroundImage = bgImage;

      // Render the canvas to display the background image
      canvas.requestRenderAll();
    };
  };
};

  // Add an image to the canvas
  const addImageToCanvas = (file, id) => {
    if (!file|| !canvas) return;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let imageUrl = e.target.result
      let imageElement = document.createElement('img');
      imageElement.src = imageUrl
      imageElement.onload = function () {
        let image = new fabric.Image(imageElement)
        image.set({ id });
        canvas.add(image)
        canvas.centerObject(image)
        canvas.setActiveObject(image)
      }
    }
  };

  const updateImageData = (id, obj) => {
    setImageData((prev) => ({
      ...prev,
      [id]: {
        left: obj.left,
        top: obj.top,
        width: obj.width * obj.scaleX,
        height: obj.height * obj.scaleY,
      },
    }));
  };

  const deleteSelectedObject = useCallback(() => {
    if (!canvas) return;
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      canvas.remove(activeObject);
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
  }, [canvas]);

  // Keyboard listener using deleteSelectedObject from useCallback
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteSelectedObject();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [deleteSelectedObject]);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare data to send to backend
    const formData = new FormData();
    formData.append('name', templateName);
    formData.append('folderId', folderId);

    if (backgroundImage) {
      formData.append('backgroundImage', backgroundImage);
    }
    if (club_logoImage) {
      formData.append('club_logoImage', club_logoImage);
      formData.append('clubLogoPositionData', JSON.stringify(imageData['clubLogo']));
      console.log('Position Data Final:', imageData['clubLogo']);
    }
    if (position_fieldImage) {
      formData.append('position_fieldImage', position_fieldImage);
      formData.append('positionFieldPositionData', JSON.stringify(imageData['positionField']));
      console.log('Position Data Final:', imageData['positionField']);
    }
    if (name_fieldImage) {
      formData.append('name_fieldImage', name_fieldImage);
      formData.append('nameFieldPositionData', JSON.stringify(imageData['nameField']));
      console.log('Position Data Final:', imageData['nameField']);
    }
    if (sponsor_logoImage) {
      formData.append('sponsor_logoImage', sponsor_logoImage);
      formData.append('sponsorLogoPositionData', JSON.stringify(imageData['sponsorLogo']));
      console.log('Position Data Final:', imageData['sponsorLogo']);
    }
    if (backsideImage) {
      formData.append('backsideImage', backsideImage);
    }

  
    const apiBaseUrl = process.env.REACT_APP_API_URL;
    console.log('API Base URL:', apiBaseUrl);
  
    fetch(`${apiBaseUrl}/api/templates`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          // Read the response as text to capture any error messages
          return response.text().then((text) => {
            throw new Error(`HTTP ${response.status} ${response.statusText}: ${text}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        // Handle success, e.g., redirect or display a message
        console.log('Template created:', data);
        navigate('/templates');
      })
      .catch((error) => {
        console.error('Error creating template:', error);
        // Display error message to the user if needed
      });
  };

  // Handlers for file uploads
  const handleBackgroundUpload = (e) => {
    const file = e.target.files[0];
    setBackgroundImage(file);
    setCanvasBackground(file);
  };

  const handleClub_logoUpload = (e) => {
    const file = e.target.files[0];
    setClub_logoImage(file);
    addImageToCanvas(file, 'clubLogo');
  };

  const handlePosition_fieldUpload = (e) => {
    const file = e.target.files[0];
    setPosition_fieldImage(file);
    addImageToCanvas(file, 'positionField'); // Set single file
  };

  const handleName_fieldUpload = (e) => {
    const file = e.target.files[0];
    setName_fieldImage(file);
    addImageToCanvas(file, 'nameField'); // Set single file
  } 

  const handleSponsor_logoUpload = (e) => {
    const file = e.target.files[0];
    setSponsor_logoImage(file);
    addImageToCanvas(file, 'sponsorLogo'); // Set single file
  };

  const handleBacksideUpload = (e) => {
    setBacksideImage(e.target.files[0]);// Set single file
  };

  return (
    <div className='templates-page'>
    <Sidebar />
     <div className='template-content'>
      <h1>Create New Template</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Template Name*:
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            required
          />
        </label>
        <label>
          Google FolderId*:
          <input
            type="text"
            value={folderId}
            onChange={(e) => setFolderId(e.target.value)}
            required
          />
        </label>
        <label>
          Background*:
          <input
            type="file"
            onChange={handleBackgroundUpload}
            required
          />
        </label>
        <label>
          Backside*:
          <input
            type="file"
            onChange={handleBacksideUpload}
            required
          />
        </label>
        <label>
          Position Feld*:
          <input
            type="file"
            onChange={handlePosition_fieldUpload}
            required
          />
        </label>
        <label>
          Namens Feld:
          <input
            type="file"
            onChange={handleName_fieldUpload}
          />
        </label>
        <label>
          Club Logo:
          <input
            type="file"
            onChange={handleClub_logoUpload}
          />
        </label>
        <label>
          Sponsoren Logo:
          <input
            type="file"
            onChange={handleSponsor_logoUpload}
          />
        </label>
        <div id="canvas-container">
          <canvas ref={canvasRef} id="templateCanvas" width="800" height="600" style={{border: '1px solid #ccc'}}></canvas>
        </div>
        <button type="submit">Save Template</button>
      </form>
      </div>
    </div>
  );
}

export default TemplateCreate;
