import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Sidebar from '../components/sidebar';
import "./css/Automatic.css";

const Automatic = () => {
    const [nameListData, setNameListData] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [processing, setProcessing] = useState(false);
    const [folderId, setFolderId] = useState("");

    const API_URL = process.env.REACT_APP_API_URL;

    // Fetch templates on component mount
    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await fetch(`${API_URL}/api/templates`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setTemplates(data); // Assuming the backend returns an array of templates
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };

        fetchTemplates();
    }, [API_URL]);

    const handleFolderidChange = (e) => {
        setFolderId(e.target.value); 
    };

    const handleTemplateChange = (e) => {
        setSelectedTemplate(e.target.value); 
    };

    const handleNameListChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          const fileExtension = file.name.split('.').pop().toLowerCase();
      
          if (fileExtension === 'csv') {
            handleCSV(file);
          } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
            handleXLSX(file);
          } else {
            alert('Unsupported file type!');
          }
        }
      };
      
    const handleCSV = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target.result;
          const lines = text.split('\n').map(line => line.split(','));
          setNameListData(lines);
        };
        reader.readAsText(file);
    };

    const handleXLSX = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setNameListData(jsonData);
        };
        reader.readAsArrayBuffer(file);
    };      

    const handleSubmit = async (event) => {
        setProcessing(true);
        event.preventDefault();
        
        const formData = new FormData(event.target);
    
        // Append selected template ID to form data
        formData.append('template_id', selectedTemplate);
    
        try {
            // Send the form data to the backend
            const response = await fetch(`${API_URL}/upload/automatic`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
    
            // Trigger the download
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
    
            // Use a meaningful filename or get it from the response headers
            link.download = 'cards.zip'; 
            document.body.appendChild(link);
            link.click();
            link.remove();
    
            // Clean up the object URL
            window.URL.revokeObjectURL(url);

            setProcessing(false);
    
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="automatic-page">
            <Sidebar />
            <h1>This is Page "automatic"</h1>
            <div className="automatic-content">
                <form id="automatic-upload-form" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="folderId-field">
                        <p>Folder-ID</p>
                        <input type="text" name="folder-id" placeholder="Insert folder-id" value={folderId} onChange={handleFolderidChange} />
                    </div>
                    <div className="template-field">
                        <p>Template</p>
                        <select
                            name="template_id"
                            value={selectedTemplate}
                            onChange={handleTemplateChange}
                            required
                        >
                            <option value="" disabled>Select a template</option>
                            {templates.map((template) => (
                                <option key={template.id} value={template.id}>
                                    {template.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='list-preview'>
                        <p>List Preview</p>
                        <input
                            type="file"
                            name="name-list"
                            accept=".csv, .xlsx, .xls"
                            onChange={handleNameListChange}
                        />
                        <div className="scrollable-container">
                            {nameListData.length > 0 ? (
                            <table>
                                <tbody>
                                {nameListData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            ) : (
                            <div className="placeholder-box">Name-List Preview</div>
                            )}
                        </div>
                    </div>
                    <div className="auto-create-button">
                        <button type="submit" disabled={processing}>
                            {processing ? 'Processing...' : 'Generate'}
                        </button>
                    </div>
                </form>    
            </div>
        </div>
    );
};

export default Automatic;
