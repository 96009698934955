import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import * as fabric from 'fabric';
import Sidebar from './sidebar';

function TemplateEdit() {
  const { templateId } = useParams(); // get the templateId from the URL
  
  const [templateName, setTemplateName] = useState('');
  const [folderId, setFolderId] = useState('');
  const [backgroundImage, setBackgroundImage] =useState(null);
  const [club_logoImage, setClub_logoImage] =useState(null);
  const [position_fieldImage, setPosition_fieldImage] =useState(null);
  const [sponsor_logoImage, setSponsor_logoImage] =useState(null);
  const [name_fieldImage, setName_fieldImage] =useState(null);
  const [backsideImage, setBacksideImage] =useState(null);
  const navigate = useNavigate();

  const [canvas, setCanvas] = useState(null);
  const canvasRef = useRef(null);
  const [imageData, setImageData] = useState({});

  const apiBaseUrl = process.env.REACT_APP_API_URL;

  // Function to load and add an image to the canvas
  const imageFromUrl = useCallback((url, positionData = null, id = null) => {
    if (!url) {
      console.error("Error: Image URL is required.");
      return;
    }
  
    if (positionData);
  
    const imageElement = document.createElement('img');
    imageElement.src = url;
  
    imageElement.onload = () => {
      const fabricImage = new fabric.Image(imageElement, {
        left: positionData?.left || 0,
        top: positionData?.top || 0,
      });
  
      // Assign unique ID for tracking
      fabricImage.set({ id: id || `image_${Date.now()}` });
  
      if (positionData?.width && positionData?.height) {
        fabricImage.scaleX = positionData.width / imageElement.width;
        fabricImage.scaleY = positionData.height / imageElement.height;
      }
  
      canvas.add(fabricImage);
      canvas.requestRenderAll();
    };
  
    imageElement.onerror = () => {
      console.error("Error loading image:", url);
    };
  }, [canvas]);
  

  // Fetch the existing template data on mount to pre-fill fields
  useEffect(() => {
    if (!canvas) return;
  
    fetch(`${apiBaseUrl}/api/templates/${templateId}`)
      .then(res => res.json())
      .then(data => {
        if (data) {
          setTemplateName(data.template_name);
          setFolderId(data.google_folder_id);
  
          const bgImageUrl = `${apiBaseUrl}${data.backgroundImage}`;
          const bgImageElement = document.createElement('img');
          bgImageElement.src = bgImageUrl;
  
          bgImageElement.onload = () => {
            const bgImage = new fabric.Image(bgImageElement);
            canvas.setWidth(bgImage.width);
            canvas.setHeight(bgImage.height);
            canvas.backgroundImage = bgImage;
  
            //  Pass ID so object modifications are tracked
            if (data.club_logoImage && data.clubLogoPositionData) {
              const clImageUrl = `${apiBaseUrl}${data.club_logoImage}`;
              imageFromUrl(clImageUrl, data.clubLogoPositionData, 'clubLogo');
            }
  
            if (data.sponsor_logoImage && data.sponsorLogoPositionData) {
              const slImageUrl = `${apiBaseUrl}${data.sponsor_logoImage}`;
              imageFromUrl(slImageUrl, data.sponsorLogoPositionData, 'sponsorLogo');
            }
  
            if (data.position_fieldImage && data.positionFieldPositionData) {
              const pfImageUrl = `${apiBaseUrl}${data.position_fieldImage}`;
              imageFromUrl(pfImageUrl, data.positionFieldPositionData, 'positionField');
            }
            
            if (data.name_fieldImage && data.nameFieldPositionData) {
              const nfImageUrl = `${apiBaseUrl}${data.name_fieldImage}`;
              imageFromUrl(nfImageUrl, data.nameFieldPositionData, 'nameField');
            }
  
            canvas.requestRenderAll();
          };
        }
      })
      .catch(error => console.error('Error fetching template:', error));
  }, [apiBaseUrl, templateId, canvas, imageFromUrl]);
  

    // Initialize Fabric.js canvas
    useEffect(() => {
      if (canvasRef.current) {
        const initCanvas = new fabric.Canvas(canvasRef.current, {
          width: 400,
          height: 300,
        });
    
        initCanvas.backgroundColor = '#fff';
        initCanvas.renderAll();
    
        //  Ensure object movements are tracked
        initCanvas.on('object:modified', (event) => {
          const obj = event.target;
          if (obj && obj.id) {
            updateImageData(obj.id, obj);
            console.log(`Updated position for ${obj.id}:`, obj.left, obj.top);
          }
        });
    
        setCanvas(initCanvas);
        return () => {
          initCanvas.dispose();
        };
      }
    }, []);
    
  
  const setCanvasBackground = (file) => {
    if (!file || !canvas) return;
  
    const reader = new FileReader();
    reader.readAsDataURL(file);
  
    reader.onload = (e) => {
      const bgImageUrl = e.target.result
      const bgImageElement = document.createElement('img');
      bgImageElement.src = bgImageUrl;
  
      bgImageElement.onload = () => {
        const bgImage = new fabric.Image(bgImageElement);
  
        // Resize the canvas to match the image dimensions
        canvas.setWidth(bgImage.width);
        canvas.setHeight(bgImage.height);
  
        // Assign the background image directly
        canvas.backgroundImage = bgImage;
  
        // Render the canvas to display the background image
        canvas.requestRenderAll();
      };
    };
  };
  
    // Add an image to the canvas
    const addImageToCanvas = (file, id) => {
      if (!file || !canvas) return;
    
      let reader = new FileReader();
      reader.readAsDataURL(file);
    
      reader.onload = (e) => {
        let imageUrl = e.target.result;
        let imageElement = document.createElement('img');
        imageElement.src = imageUrl;
    
        imageElement.onload = function () {
          //  Check if an object with the same ID exists
          const existingObject = canvas.getObjects().find((obj) => obj.id === id);
    
          let left = 100, top = 100, scaleX = 1, scaleY = 1; // Default values
    
          if (existingObject) {
            //  Extract position & size from existing object
            left = existingObject.left;
            top = existingObject.top;
            scaleX = existingObject.scaleX;
            scaleY = existingObject.scaleY;
    
            // Remove the old object from the canvas
            canvas.remove(existingObject);
          }
    
          //  Add the new image with the same position & size
          let newImage = new fabric.Image(imageElement, { left, top, scaleX, scaleY });
    
          //  Assign ID so it can be tracked
          newImage.set({ id });
    
          canvas.add(newImage);
          canvas.requestRenderAll();
        };
      };
    };
    
  
    const updateImageData = (id, obj) => {
      setImageData((prev) => {
        const updatedData = {
          ...prev,
          [id]: {
            left: obj.left || 0,
            top: obj.top || 0,
            width: (obj.width * obj.scaleX) || obj.width || 0,
            height: (obj.height * obj.scaleY) || obj.height || 0,
          },
        };
        console.log("Updated Image Data:", updatedData);
        return updatedData;
      });
    };    
  
    const deleteSelectedObject = useCallback(() => {
      if (!canvas) return;
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
        canvas.discardActiveObject();
        canvas.requestRenderAll();
      }
    }, [canvas]);
  
    // Keyboard listener using deleteSelectedObject from useCallback
    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'Delete' || event.key === 'Backspace') {
          deleteSelectedObject();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [deleteSelectedObject]);

    const handleSubmit = (e) => {
      e.preventDefault();
    
      const formData = new FormData();
      formData.append('name', templateName);
      formData.append('folderId', folderId);
    
      if (backgroundImage) formData.append('backgroundImage', backgroundImage);
      if (club_logoImage) formData.append('club_logoImage', club_logoImage);
      if (position_fieldImage) formData.append('position_fieldImage', position_fieldImage);
      if (sponsor_logoImage) formData.append('sponsor_logoImage', sponsor_logoImage);
      if (backsideImage) formData.append('backsideImage', backsideImage);
      if (name_fieldImage) formData.append('name_fieldImage', name_fieldImage);
    
      //  Ensure the latest position data is included in PATCH request
      console.log("Submitting Updated Image Data:", imageData);
    
      if (imageData['clubLogo']) {
        formData.append('clubLogoPositionData', JSON.stringify(imageData['clubLogo']));
      }
      if (imageData['sponsorLogo']) {
        formData.append('sponsorLogoPositionData', JSON.stringify(imageData['sponsorLogo']));
      }
      if (imageData['positionField']) {
        formData.append('positionFieldPositionData', JSON.stringify(imageData['positionField']));
      }
      if (imageData['nameField']) {
        formData.append('nameFieldPositionData', JSON.stringify(imageData['nameField']));
      }
    
      fetch(`${apiBaseUrl}/api/templates/${templateId}`, {
        method: 'PATCH',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            return response.text().then((text) => {
              throw new Error(`HTTP ${response.status} ${response.statusText}: ${text}`);
            });
          }
          return response.json();
        })
        .then((data) => {
          console.log('Template Updated:', data);
          navigate('/templates');
        })
        .catch((error) => {
          console.error('Error updating template:', error);
        });
    };

 // Handlers for file uploads
 const handleBackgroundUpload = (e) => {
  const file = e.target.files[0];
  setBackgroundImage(file);
  setCanvasBackground(file);
};

const handleClub_logoUpload = (e) => {
  const file = e.target.files[0];
  setClub_logoImage(file);
  addImageToCanvas(file, 'clubLogo'); 
};

const handleSponsor_logoUpload = (e) => {
  const file = e.target.files[0];
  setSponsor_logoImage(file);
  addImageToCanvas(file, 'sponsorLogo');
};

const handlePosition_fieldUpload = (e) => {
  const file = e.target.files[0];
  setPosition_fieldImage(file);
  addImageToCanvas(file, 'positionField');
};

const handleName_fieldUpload = (e) => {
  const file = e.target.files[0];
  setName_fieldImage(file);
  addImageToCanvas(file, 'nameField');
}

const handleBacksideUpload = (e) => {
  setBacksideImage(e.target.files[0]);
};

  return (
    <div className='templates-page'>
      <Sidebar />
      <div className='template-content'>
      <h1>Edit Template</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Template Name:
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            required
          />
        </label>
        <label>
          Google FolderId:
          <input
            type="text"
            value={folderId}
            onChange={(e) => setFolderId(e.target.value)}
            required
          />
        </label>
        <label>
          Background:
          <input type="file" onChange={handleBackgroundUpload} />
        </label>
        <label>
          Backside:
          <input type="file" onChange={handleBacksideUpload} />
        </label>
        <label>
          Position Feld:
          <input type="file" onChange={handlePosition_fieldUpload} />
        </label>
        <label>
          Namens Feld:
          <input type="file" onChange={handleName_fieldUpload} />
        </label>
        <label>
          Club Logo:
          <input type="file" onChange={handleClub_logoUpload} />
        </label>
        <label>
          Sponsoren Logo:
          <input type="file" onChange={handleSponsor_logoUpload} />
        </label>
        <div id="canvas-container">
          <canvas ref={canvasRef} id="templateCanvas" width="800" height="600" style={{border: '1px solid #ccc'}}></canvas>
        </div>
        <button type="submit">Save Template</button>
      </form>
      </div>
    </div>
  );
}

export default TemplateEdit;